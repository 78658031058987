import { type ActionFunctionArgs, json } from "@remix-run/node";
import { requireUser } from "~/session.server";
import { rateLimit, COMMON_LIMITS } from "~/lib/rate-limit";
import { z } from "zod";
import { parseWithZod } from "@conform-to/zod";
import { Template, sendTemplateMail } from "~/lib/email.server";
import envs from "~/envs.server";

export const formSchema = z.object({
  text: z.string().trim().min(32).max(1000),
});

export async function action({ request }: ActionFunctionArgs) {
  const user = await requireUser(request);

  const submission = parseWithZod(await request.formData(), { schema: formSchema });

  if (submission.status !== "success") {
    return json(submission.reply(), { status: submission.status === "error" ? 400 : 200 });
  }
  await rateLimit(request, COMMON_LIMITS.ISSUE_REPORT);

  await sendTemplateMail(envs.SUPPORT_EMAIL, Template.issueReport, {
    id: user.id,
    name: user.name,
    email: user.email,
    activeOrganisationId: user.activeOrganizationId,
    text: submission.value.text,
  });

  return json(submission.reply());
}
